<template>
  <div class="event-info-container bg-dark text-light position-relative">
    <div class="container-md">
      <div class="row py-5">

        <div class="col-12 col-sm-5 col-lg-5 order-sm-1 my-2 text-sm-center" v-if="eventDate">
          <div class="row">
            <div class="col-2 col-sm-12 my-sm-2 text-center">
              <font-awesome-icon :icon="['fal', 'calendar-alt']" class="calendar-icon"/>
            </div>
            <div class="col-10 col-sm-12 my-sm-2">
              {{ eventDate | moment("DD.MM.YY") }} | {{ eventDate | moment("LT") }} Uhr
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-7 col-lg-7 order-sm-0 my-2">
          <h1 class="title" v-if="eventTitle">{{ eventTitle }}</h1>
          <p v-if="eventDescription">{{ eventDescription }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediainfoEventInfo",
  props: {
    eventTitle: {
      type: String,
      default: ""
    },
    eventDescription: {
      type: String,
      default: ""
    },
    eventDate: {
      type: String
    }
  }
}
</script>

<style scoped>
.calendar-icon {
  font-size: 1.4rem;
}

@media (min-width: 568px) {
  .calendar-icon {
    font-size: 2.4rem;
  }
}
</style>