<template>
  <div class="row no-gutters">
    <div class="col-6">
      <button v-on:click.prevent="onThumbUpClick" class="btn shadow-none p-0">
        <font-awesome-icon :icon="['fal', 'thumbs-up']" size="2x"/>
      </button>
      <b-badge class="rounded mx-2 useraction__badge">{{ emoji_thumb_up }}</b-badge>
    </div>
    <div class="col-6">
      <button v-on:click.prevent="onHeartClick" class="btn shadow-none p-0">
        <font-awesome-icon :icon="['fal', 'heart']" size="2x"/>
      </button>
      <b-badge class="rounded mx-2 useraction__badge">{{ emoji_heart }}</b-badge>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {UserActionsService} from "@/service/useractions.service"

export default {
  name: "UserActions",
  computed: {
    ...mapState("useractions", ['emoji_applause', 'emoji_heart', 'emoji_thumb_up']),
    ...mapState("event", ['streaming_event'])
  },
  methods: {
    onThumbUpClick() {
      UserActionsService.submitAction({
        action: 'emoji_thumb_up',
        streaming_event: this.streaming_event.uuid
      })
    },
    onHeartClick() {
      UserActionsService.submitAction({
        action: 'emoji_heart',
        streaming_event: this.streaming_event.uuid
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.useraction__badge {
  font-size: 100%;
}
</style>