const state = {
  muted: false
}

const actions = {
  mute({commit}) {
    commit("MUTE_PLAYER")
  },
  unmute({commit}) {
    commit("UNMUTE_PLAYER")
  }
}

const mutations = {
  MUTE_PLAYER(state) {
    console.log("MUTE_PLAYER")
    state.muted = true
  },
  UNMUTE_PLAYER(state) {
    console.log("UNMUTE_PLAYER")
    state.muted = false
  }
}

export const videoplayer = {
  namespaced: true,
  state,
  actions,
  mutations
}