import ApiService from "@/service/api.service"

const UserActionsService = {
  update: async function (store, key) {
    store.commit("useractions/UPDATING", true)
    await this.get(key)
        .then(response => {
          store.commit("useractions/UPDATE_ITEMS", response.data.streaming_user_actions)
        })
        .catch(error => {
          console.log(error)
        })
    store.commit("useractions/UPDATING", false)
  },
  get: async function (key) {
    return await ApiService.get("api/v2/useraction/" + key)
  },
  submitAction: async function (data) {
    try {
      const response = await ApiService.post("/api/v2/useraction?XDEBUG_SESSION_START=phpstorm", data)
      return response.data
    }
    catch (error) {
      console.log(error)
      throw (error)
    }
  }
}

export {UserActionsService}
