<template>
  <div id="chat" class="chat bg-light p-5">
    <p class="py-2">Schreiben Sie Ihre Frage an das Panel. Ein Moderator wird Ihre Nachricht erhalten.</p>
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Ihr Name" label-for="name">
        <b-form-input id="name" v-model="form.name" placeholder="Ihr Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Ihre Nachricht" label-for="text">
        <b-form-textarea id="text" v-model="form.text" placeholder="Ihre Nachricht" required></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary" block :disabled="submit_timer_active">Absenden
        <countdown :time="submit_timer" :interval="1000" v-if="submit_timer_active">
          <template slot-scope="props">
            <small>({{props.seconds}})</small>
          </template>
        </countdown>
      </b-button>
    </b-form>
    <loading :active="is_refreshing" :is-full-page="false"></loading>
    <div class="thankyou-overlay" v-if="show_thankyouoverlay">
      <div class="thankyou-overlay__bg"></div>
      <div class="thankyou-overlay__body text-center">
        <h3>Vielen Dank! Ihre Nachricht ist angekommen.</h3>
        <b-iconstack font-scale="5">
          <b-icon-circle-fill stacked variant="success"></b-icon-circle-fill>
          <b-icon-check stacked variant="light"></b-icon-check>
        </b-iconstack>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Chat",
  components: {Loading},
  data() {
    return {
      show: true,
      submit_timer: 60000,
      submit_timer_active: false,
      show_thankyouoverlay: false,
      disclaimer_confirmed: true,
      form: {
        name: '',
        text: ''
      }
    }
  },
  computed: {
    ...mapState("chat", ["has_error", "is_refreshing"]),
    ...mapState("event", ["uuid"]),
    ...mapGetters("event", ['getChatMode'])
  },
  methods: {
    ...mapActions("chat", ["submitData"]),
    onSubmit() {
      this.startTimer()
      this.showThankyou()

      var transport_data = {
        'streaming_event': this.uuid,
        'name': this.form.name,
        'message': this.form.text
      }

      this.submitData(transport_data)
      this.form.text = ' '
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.name = ''
      this.form.text = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    showThankyou() {
      this.show_thankyouoverlay = true
      setTimeout(() => {
        this.show_thankyouoverlay = false
      }, 2000)
    },
    startTimer() {
      this.submit_timer_active = true
      setTimeout(() => {
        this.submit_timer_active = false
      }, this.submit_timer)
    }
  }
}
</script>

<style scoped>
.chat {
  position: relative;
}

.thankyou-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8000;
}

.thankyou-overlay.is-active {
  display: flex;
}

.thankyou-overlay__bg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
  backdrop-filter: blur(2px);
}

.thankyou-overlay__body {
  position: relative;
}
</style>
