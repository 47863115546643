import ApiService from "@/service/api.service";

const CheckHashService = {
  checkHash: async function (key) {
    try {
      return await ApiService.get("/api/" + process.env.VUE_APP_API + "/hash/" + key)
          .then(response => response.data)
    } catch (error) {
      console.log(error)
    }
  }
}

export { CheckHashService }
