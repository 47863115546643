import { render, staticRenderFns } from "./GallerItem.vue?vue&type=template&id=253c90bb&scoped=true&"
import script from "./GallerItem.vue?vue&type=script&lang=js&"
export * from "./GallerItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253c90bb",
  null
  
)

export default component.exports