<template>
  <div class="footer-nav">
    <div class="bg-dark text-light">
      <div class="container">
        <nav class="nav bg-dark py-2 justify-content-end small">
          <b-link class="nav-link text-light" v-b-modal:imprintData>Impressum</b-link>
          <b-link class="nav-link text-light" v-b-modal:disclaimerData>Datenschutz</b-link>
        </nav>
      </div>
    </div>

    <b-modal
        id="imprintData"
        size="xl"
        title="Impressum"
        ok-only
        ok-variant="light"
        ok-title="Ok"
        header-bg-variant="dark"
        header-text-variant="white">
      <div class="py-3 px-2 min-vh-35" v-html="impressumText"></div>
    </b-modal>
    <b-modal id="disclaimerData"
             size="xl"
             title="Datenschutz"
             ok-only
             ok-variant="light"
             ok-title="Ok"
             header-bg-variant="dark"
             header-text-variant="white">
      <div class="py-3 px-2 min-vh-35" v-html="datenschutzText"></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
  props: {
    impressumText: {
      type: String
    },
    datenschutzText: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
