<template>
  <div class="plyr position-relative h-100 w-100">
    <video ref="videoPlayer" muted autoplay playsinline class="video-js vjs-16-9 vjs-big-play-centered h-100 w-100"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import 'videojs-contrib-quality-levels'
import canAutoplay from "can-autoplay"

export default {
  name: 'VideoPlayer',
  props: {
    playerPoster: {
      type: String
    },
    playerAutoplay: {
      type: Boolean,
      default: true
    },
    playerSeekbar: {
      type: Boolean,
      default: true
    },
    playerSource: {
      type: [Object, Boolean],
      default: false
    },
    videojsOptions: {
      type: Object,
      default: function () {
        return {
          controlBar: {
            volumePanel: {
              inline: false
            },
            progressControl: {},
          },
          html5: {
            vhs: {
              withCredentials: true,
              overrideNative: true,
              enableLowInitialPlaylist: true,
              limitRenditionByPlayerDimensions: true
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false
          },
          muted: true,
          autoplay: true,
          controls: true,
          errorDisplay: false,
          sources: [],
        }
      }
    }
  },
  data() {
    return {
      player: null,
    }
  },
  watch: {
    playerPoster: function (value) {
      this.player.poster(value)
    },
    playerAutoplay: function (value) {
      this.player.autoplay(value)
    },
    playerSeekbar: function (value) {
      value ? this.player.controlBar.progressControl.enable() : this.player.controlBar.progressControl.disable()
    },
    playerSource: function (value) {
      this.initPlaylist(value)
    }
  },
  methods: {
    initPlayer: function () {
      this.player = videojs(this.$refs.videoPlayer, this.videojsOptions)
      this.player.qualityLevels();

      // Hintergrundbild
      this.player.poster(this.playerPoster)

      canAutoplay.video().then(({result}) => {
        this.player.muted(!result)
      })

      // Autoplay
      this.player.autoplay(this.playerAutoplay)

      // Player Seekbar
      this.playerSeekbar ? this.player.controlBar.progressControl.enable() : this.player.controlBar.progressControl.disable()
    },
    initPlaylist: function (src) {
      this.player.src(src)
    },
    initEventhandling: function () {

      //
      // Use VueJS Event Bus for VideoControl Elements
      //
      this.$root.$on('handleVideoplayerMuteControl', () => {
        const vol = this.player.volume();
        const lastVolume = this.player.lastVolume_();

        if (vol === 0) {
          const volumeToSet = lastVolume < 0.1 ? 0.1 : lastVolume;
          this.player.volume(volumeToSet);
          this.player.muted(false);
        }
        else {
          this.player.muted(!this.player.muted());
        }
      })

      //
      // Videoplayer Mute/Unmute > VueX
      //
      this.player.on(['loadstart', 'volumechange'], () => {
        if (this.player.volume() === 0 || this.player.muted()) {
          this.$store.dispatch("videoplayer/mute")
        }
        else {
          this.$store.dispatch("videoplayer/unmute")
        }
      })

      // At this point we have a src...
      // Load player every x seconds new if src file is not ready
      this.player.tech().on('retryplaylist', (e) =>{
        console.log('RetryPlayList: ' + e)
                console.log(e)
      })

      this.player.tech().on('usage', (e) =>{
        console.log('Usage: ' + e)
        console.log(e)
      })

      this.player.on('resolutionchange', (e) => {
        console.log(e)
      })

      // Wird getriggert wenn der Player online kommt, aber der Stream noch nicht bereit ist.
      // Dann reload (alle 5 sek)
      this.player.on('error', () => {
        if (this.player.error().code === 4) {
          this.player.retryLock = setTimeout(() => {
            this.$router.go()
          }, 5000);
        }
      })
    },
  },
  mounted() {
    this.initPlayer()
    this.initPlaylist(this.playerSource)
    this.initEventhandling()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>

</style>
