<template>
  <div class="py-3">
    <UploadImages @changed="handleFileUpload" :max="3" upload-msg="Hier klicken, oder Bilder ablegen um diese hochzuladen." class="mb-3"/>

    <b-checkbox class="mb-3" required v-model="datenschutz_confirmed">
      <small>Ich stimme zu, dass meine Bilder nach dem Upload auf der Veranstaltung gezeigt werden können. Hinweis:
        Sie können Ihre Einwilligung jederzeit für die Zukunft widerrufen. Detaillierte Informationen zum Umgang mit
        Nutzerdaten finden Sie in unserer Datenschutzerklärung. (Link unten)</small>
    </b-checkbox>

    <b-button block size="lg" :disabled="!datenschutz_confirmed" v-on:click="submitFile">
      <font-awesome-icon :icon="['fal', 'cloud-upload']"/>
      Bild(er) einsenden...
    </b-button>

    <loading :active="is_uploading" :is-full-page="false"></loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import UploadImages from "vue-upload-drop-images"
import axios from "axios";

export default {
  name: "FileUpload",
  components: {
    UploadImages,
    Loading
  },
  props: {
    streaming_key: String
  },
  data() {
    return {
      files: '',
      is_uploading: false,
      datenschutz_confirmed: false
    }
  },
  methods: {
    handleFileUpload(files) {
      this.files = files
      console.log(files)
    },
    submitFile() {
      this.datenschutz_confirmed = true
      let formData = new FormData();
      formData.append('key', this.streaming_key)
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append('files[' + i + ']', file);
      }

      axios.post('https://digitalstream.online/streaming_gallery/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.datenschutz_confirmed = false
        this.files = []
        console.log('Success')
      })
    }
  }
}
</script>

<style scoped>

</style>