<template>
  <div class="gallery__item col-12 col-sm-6 col-md-4 col-lg-3">
    <transition name="fade">
      <a :href="imageUrl">
        <b-img :src="thumbUrl" v-on:load="onLoaded" v-show="loaded" fluid-grow/>
      </a>
    </transition>
    <loading :active="is_loading" :is-full-page="false"></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "GallerItem",
  props: ['imageUrl', 'thumbUrl'],
  components: {Loading},
  data: function () {
    return {
      loaded: false,
      is_loading: true
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true
      this.is_loading = false
    },
  }
}
</script>

<style scoped>

</style>