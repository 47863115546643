<template>
    <div class="header-media text-center bg-dark">
      <div class="header-media__countdown position-relative">
        <div :style="getHeaderStyle" class="header-media__bg"></div>
        <EventCountDown :eventdate="eventDate" class="header-media-countdown"></EventCountDown>
      </div>
    </div>
</template>

<script>
import EventCountDown from "@/components/EventCountDown"

export default {
  name: "HeaderCountdown",
  components: {
    EventCountDown
  },
  props: {
    backgroundImage: {
      type: String
    },
    eventDate: {
      type: Number
    }
  },
  computed: {
    getHeaderStyle() {
      const backgroundImageUrl = 'url(' + this.backgroundImage + ')'

      return {
        "background-image": backgroundImageUrl
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header-media {
  &__countdown {
    height: 500px;
  }

  &__bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media (min-width: 768px) {
  .header-media {
    &__bg {
      &:before, &:after {
        content: "";
        top: 0;
        position: absolute;
        height: 100%;
        width: 15%;
        z-index: 2;
      }

      &:before {
        left: 0;
        background: -webkit-linear-gradient(left, #000 0, #0000 100%);
        background: linear-gradient(to right, #000 0, #0000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
      }

      &:after {
        right: 0;
        background: -webkit-linear-gradient(left, #0000 0, #000 100%);
        background: linear-gradient(to right, #0000 0, #000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=1);
      }
    }
  }
}
</style>