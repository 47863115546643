import axios from "axios"
import {TokenService} from "./token.service"
import store from "../store"
import router from "@/router"

const API_URL = process.env.VUE_APP_API_BASE_URL ? process.env.VUE_APP_API_BASE_URL : 'https://digitalstream.online'

const ApiService = {
  _401interceptor: null,
  _403interceptor: null,

  /*
   Base REST API website url.
  */
  init() {
    axios.defaults.baseURL = API_URL;
    axios.defaults.withCredentials = true
    this.mount403Interceptor()
  },

  // Add or remove access token header.
  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  // Request types.
  get(resource) {
    return axios.get(resource);
  },

  async post(resource, data) {
    if (!axios.defaults.headers.post['X-CSRF-Token']) {
      await axios.get("session/token").then((response) => {
        axios.defaults.headers.post['X-CSRF-Token'] = response.data
      })
    }
    return axios.post(resource, data);
  },

  async put(resource, data) {
    if (!axios.defaults.headers.post['X-CSRF-Token']) {
      await axios.get("session/token").then((response) => {
        axios.defaults.headers.post['X-CSRF-Token'] = response.data
      })
    }
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },

  // Intercept 401 Authentication errors and process them:
  // - If it is during login, then clean up auth store, user can try again.
  // - If it is other request, then try to refresh the access token.
  // IMPORTANT NOTE:
  // Drupal returns 403 code instead of 401. Here I will check against 403,
  // but leave the name 401, as it should be by convention.
  mount401TokenInterceptor() {
    this._401interceptor = axios.interceptors.response.use(
        response => {
          return response;
        },

        async error => {
          if (error.request.status === 403) {
            if (error.config.url.includes("/oauth/token")) {
              store.dispatch("auth/logout");
              throw error;
            }
            else {
              try {
                await store.dispatch("auth/refreshToken");
                return this.customRequest({
                  method: error.config.method,
                  url: error.config.url,
                  data: error.config.data
                });
              }
              catch (e) {
                throw error;
              }
            }
          }

          throw error;
        }
    );
  },
  unmount401TokenInterceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },
  mount403Interceptor() {
    this._403interceptor = axios.interceptors.response.use(
        response => {
          return response
        },
        async error => {
          if (error.request.status === 403) {
            store.dispatch("app/stopStatusPolling")
            router.push({name: 'checkhash'})
          }
        }
    )
  },
  umount403Interceptor() {
    axios.interceptors.response.eject(this._403interceptor);
  }
}

export default ApiService;
