import Vue from "vue"
import Vuex from "vuex"

import {auth} from "./auth.module"
import {event} from "./event.module"
import {chat} from "./chat.module"
import {videoplayer} from "@/store/videoplayer.module"
import {gallery} from "@/store/gallery.module"
import {useractions} from "@/store/useractions.module";

import app from "./app.module"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    event,
    chat,
    videoplayer,
    gallery,
    useractions
  }
});
