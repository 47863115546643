import Vue from 'vue'
import VueRouter from 'vue-router'
import {TokenService} from "@/service/token.service";

import Login from "@/views/Login";
import FullPlayerPage from "@/views/FullPlayerPage";
import HashCodePage from "@/views/HashCodePage";
import UploadPage from "@/views/UploadPage";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes: [
    {
      path: "/",
      name: "home",
      component: HashCodePage
    },
    {
      path: "/live/:id?",
      name: "checkhash",
      component: HashCodePage
    },
    {
      path: "/upload/:key",
      name: "imageupload",
      component: UploadPage
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        onlyWhenLoggedOut: true
      }
    },
    // Muss als letztes stehen bleiben, da es sonst mit den anderen Pfaden
    // kollidiert
    {
      path: "/:key",
      name: "fullplayerpage",
      component: FullPlayerPage
    },
  ]
})

/*
 * Stellt sicher das routen mit "protect" ein login erfordern
 */
router.beforeEach((to, from, next) => {
  const isProtected = to.matched.some(record => record.meta.protect);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);

  // Replace with State instead of asking Tokenservice directly
  const loggedIn = !!TokenService.getToken();

  if (isProtected && !loggedIn) {
    return next({
      path: "/login",
      query: {redirect: to.fullPath}
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/");
  }

  next();
});

export default router;
