<template>
  <div class="mplyr">
    <video ref="miniVideoPlayer" autoplay class="video-js vjs-16-9 vjs-big-play-centered"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import {mapGetters, mapState} from "vuex"

export default {
  name: 'MiniVideoPlayer',
  data() {
    return {
      player: null,
      videoOptions: {
        html5: {
          vhs: {
            withCredentials: true,
            overrideNative: !videojs.browser.IS_SAFARI,
            enableLowInitialPlaylist: true,
            limitRenditionByPlayerDimensions: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        muted: false,
        autoplay: true,
        controls: true,
        errorDisplay: false,
        sources: [],
      }
    }
  },
  computed: {
    ...mapGetters("event", ["getPoster", "getPreLiveSource"]),
    ...mapState("event", ["is_started", "is_stopped", "has_pre_roll"])
  },
  methods: {
    initPlayer: function () {
      this.player = videojs(this.$refs.miniVideoPlayer, this.videoOptions)
      this.player.src(this.getPreLiveSource)
      this.player.play()
    },
  },
  mounted() {
    this.initPlayer()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>

</style>
