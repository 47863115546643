const TOKEN_KEY = "access_token";
const TOKEN_TIMEOUT_KEY = "access_token_timeout";
const REFRESH_TOKEN_KEY = "refresh_token";

// Store the access and refresh tokens in the localStorage.
// Make a separate class for this, so it is easy to swap storage type later.
const TokenService = {
  getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken, timeout) {
    window.localStorage.setItem(TOKEN_KEY, accessToken);
    window.localStorage.setItem(TOKEN_TIMEOUT_KEY, timeout);
  },

  removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

export { TokenService };
