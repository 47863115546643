<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import {UpdatecheckService} from "@/service/updatecheck.service"
import {EventService} from "@/service/event.service"
import {UserActionsService} from "@/service/useractions.service";
import {GalleryService} from "@/service/gallery.service";

export default {
  name: 'App',
  data() {
    return {
      pollIntervalInstance: null
    }
  },
  computed: {
    ...mapState("app", {
      streaming_event_key: "streaming_event_key",
      pollIntervalTime: "pollIntervalTime",
      app_status_polling: "status_polling",
      app_streaming_event: "streaming_event",
      app_streaming_actions: "streaming_actions",
      app_streaming_gallery: "streaming_gallery",
      app_streaming_message: "streaming_message"
    }),
  },
  watch: {
    //
    // Wenn im App Store die Eigenschaft "status_polling" wechselt, reagieren wir hier darauf und
    // richten den Status Polling Interval ein. 
    //
    app_status_polling(value) {
      (value === true && this.streaming_event_key.length > 1) ? this.startStatusPoll() : this.stopStatusPoll()
    },
    //
    // Wenn streaming_event value sich ändert, trigger StreamingEventService > Update
    //
    app_streaming_event(value) {
      if (value !== 0 && this.streaming_event_key.length > 1) {
        this.updateEvent()
      }
    },
    //
    // Wenn streaming_event value sich ändert, trigger StreamingActions > Update
    //
    app_streaming_actions(value) {
      if (value !== 0) {
        this.updateActions()
      }
    }
  },
  methods: {
    ...mapActions("app", [
      "setKey"
    ]),
    //
    // Triggert alle x Sekunden den UpdateCheckService
    //
    startStatusPoll() {
      this.pollIntervalInstance = setInterval(() => {
        this.updateStatus()
      }, this.pollIntervalTime)
    },
    stopStatusPoll() {
      clearInterval(this.pollIntervalInstance)
    },
    //
    // UpdateService
    //
    async updateStatus() {
      await UpdatecheckService.updateStatus(this.$store, this.streaming_event_key)
    },
    //
    // EventService
    //
    async updateEvent() {
      await EventService.updateEvent(this.$store, this.streaming_event_key)
    },
    //
    // GalleryService
    //
    async updateGallery() {
      await GalleryService.update(this.$store, this.streaming_event_key)
    },
    //
    // UserActionsService
    //
    async updateActions() {
      await UserActionsService.update(this.$store, this.streaming_event_key)
    }
  }
}
</script>
