import {ChatService} from "@/service/chat.service"

const state = {
  is_refreshing: false,
  has_error: false
}

const actions = {
  async submitData({commit}, data) {
    commit("REFRESHING")
    try {
      const response = await ChatService.submitChat(data)
      commit("PROCESSED", response)
      return true
    } catch (e) {
      commit("HAS_ERROR")
      console.log(e)
    }
  }
}

const mutations = {
  REFRESHING(state) {
    state.is_refreshing = true
  },
  PROCESSED(state, response) {
    console.log(response)
    state.is_refreshing = false
  },
  HAS_ERROR(state) {
    state.has_error = true
  }
}

export const chat = {
  namespaced: true,
  state,
  actions,
  mutations
}

