const state = {
  streaming_event: {
    uuid: "",
    name: "",
    status: true,
    changed: "",
    is_started: false,
    is_stopped: false,
    field_body: "",
    field_chat_mode: 0,
    field_datenschutz: "",
    field_event_date: "",
    field_header_bild: [],
    field_impressum: "",
    field_logo_media: [],
    field_menue: [],
    field_pre_live_video: [],
    field_pre_live_video_media: [],
    field_short_description: "",
    field_streaming_autoplay: true,
    field_streaming_gallery: true,
    field_streaming_key: "",
    field_streaming_poster: [],
    field_streaming_poster_media: [],
    field_streaming_seekbar: false,
    field_streaming_useractions: true
  },
  streaming_server: [],
  streaming_server_select: 0,
  streaming_server_auth: null,
  need_update: false,
  is_started: false,
  is_stopped: false,
  is_updating: false,
  has_data: false
}

const getters = {
  isProtected: state => {
    return (state.streaming_server_auth)
  },
  getLogo: state => {
    if (state.streaming_event.field_logo_media.length > 0) {
      return state.streaming_event.field_logo_media[0].url
    }
  },
  getPosterImage: state => {
    if (state.streaming_event.field_streaming_poster_media.length > 0) {
      return process.env.VUE_APP_API_BASE_URL + state.streaming_event.field_streaming_poster_media[0].streaming_poster.url
    }
  },
  getHeaderImage: state => {
    if (state.streaming_event.field_header_bild.length > 0) {
      return process.env.VUE_APP_API_BASE_URL + state.streaming_event.field_header_bild[0]['16_9']['url']
    }
    return false
  },
  getStreamingSource: state => {
    if (state.streaming_server.length > 0) {
      var selected_server = state.streaming_server.find((server) => {
        return server.id == state.streaming_server_select
      })
    }
    else {
      return
    }

    var streaming_url = selected_server.schema + "://" + selected_server.host + "/" + selected_server.application + "/" + state.streaming_event.field_streaming_key + "/playlist.m3u8"
    if (state.streaming_server_auth) {
      streaming_url = streaming_url + state.streaming_server_auth
    }

    return {
      src: streaming_url,
      type: "application/x-mpegURL"
    }
  },
  getPreLiveSource: state => {
    if (state.streaming_event.field_pre_live_video_media.length > 0) {
      const pre_live_video_filename = state.streaming_event.field_pre_live_video_media[0]['filename']

      return {
        src: process.env.VUE_APP_RTMP_STATIC_BASE_URL + pre_live_video_filename + "/playlist.m3u8",
        type: "application/x-mpegURL"
      }
    }

    return false
  },
  getChatEnabled: state => {
    return state.streaming_event.field_chat_mode !== 1
  },
  getDate: state => {
    if (state.streaming_event.field_event_date) {
      return new Date(state.streaming_event.field_event_date)
    }
  },
  getMenue: state => {
    return state.streaming_event.field_menue
  }
}

const actions = {
  setNeedUpdate({commit}) {
    commit("EVENT_NEED_UPDATE", true)
  },
  setIsUpdating({commit}) {
    commit("UPDATING", true)
  },
  setNotUpdating({commit}) {
    commit("UPDATING", false)
  },
  updateEvent({commit}, streaming_event) {
    commit("EVENT_UPDATED", streaming_event)
  },
  setIsStarted({commit}, value) {
    commit("EVENT_IS_STARTED", value)
  },
  setIsStopped({commit}, value) {
    commit("EVENT_IS_STOPPED", value)
  },
  removeEvent({commit}) {
    commit("REMOVE_EVENT")
  }
}

const mutations = {
  EVENT_NEED_UPDATE(state, value) {
    state.need_update = value
  },
  UPDATING(state, value) {
    state.is_updating = value
  },
  EVENT_UPDATED(state, streaming_event) {
    state.streaming_event = streaming_event.streaming_event
    state.streaming_server = streaming_event.streaming_server
    state.streaming_server_select = streaming_event.streaming_server_select
    state.streaming_server_auth = streaming_event.streaming_server_auth
    state.has_data = true
    state.is_started = streaming_event.streaming_event.is_started
    state.is_stopped = streaming_event.streaming_event.is_stopped
  },
  EVENT_IS_STARTED(state, value) {
    state.is_started = value
  },
  EVENT_IS_STOPPED(state, value) {
    state.is_stopped = value
  }
}

export const event = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}


