<template>
  <div class="header-media-playinfo bg-light position-relative">
    <div class="container-md">
      <div class="row py-5">
        <div class="col-md-5 order-md-last mb-3 mb-md-0">
          <div class="row">
            <!-- Video Controls -->
            <div class="col-4 text-left">
              <VideoControls></VideoControls>
            </div>
            <!-- User Actions -->
            <div class="col-8 text-left">
              <UserActions></UserActions>
            </div>
          </div>
        </div>
        <!-- Titel / Text -->
        <div class="col-md-7 order-md-first my-3 my-md-0">
          <h1 class="title">{{eventTitle}}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserActions from "@/components/UserActions";
import VideoControls from "@/components/VideoControls";
export default {
  name: "MediainfoPlayerControls",
  components: {VideoControls, UserActions},
  props: {
    eventTitle: {
      type: String,
      default: ""
    }
  }

}
</script>

<style scoped>

</style>