const state = {
  emoji_applause: 0,
  emoji_heart: 0,
  emoji_thumb_up: 0,
  refreshing: false,
  isUpdating: false
}

const mutations = {
  UPDATE_ITEMS(state, data) {
    state.emoji_applause = data.emoji_applause
    state.emoji_heart = data.emoji_heart
    state.emoji_thumb_up = data.emoji_thumb_up
  },
  REFRESHING(state) {
    state.refreshing = true
  },
  UPDATING(state, data) {
    state.isUpdating = data
  }
}

export const useractions = {
  namespaced: true,
  state,
  mutations
}