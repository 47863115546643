<template>
  <div>
    <b-navbar variant="faded" type="light" toggleable="md">
      <b-navbar-brand href="#">
        <img :src="logoUrl" height="50" class="image-style-logo mw-100" alt="Logo" v-if="logoUrl">
      </b-navbar-brand>

      <b-navbar-toggle target="mainMenu" v-if="menuItems"></b-navbar-toggle>

      <b-collapse id="mainMenu" is-nav v-if="menuItems">
        <b-navbar-nav fill class="ml-auto">
          <b-nav-item v-for="item in menuItems" :key="item.uri" :href="item.uri">{{ item.title }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar.vue",
  props: {
    logoUrl: {
      type: String,
      required: true
    },
    menuItems: {
      type: Array,
      defaults: []
    }
  }
}
</script>

<style scoped>
.image-style-logo {
  height: 50px;
}
</style>
