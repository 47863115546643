<template>
  <div class="gallery">
    <div class="row no-gutters">
      <GallerItem :thumb-url="image.thumb" :image-url="image.url" v-for="image in items" :key="image.uuid"></GallerItem>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import GallerItem from "@/components/GallerItem";

export default {
  name: "Gallery",
  components: {GallerItem},
  computed: {
    ...mapState("gallery", ['items']),
  }
}
</script>

<style scoped>

</style>