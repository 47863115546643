const state = {
  streaming_event_key: '',
  streaming_event_member: '',
  title: "digitalstream.online",
  status_polling: false,
  is_polling: false,
  pollIntervalTime: 2000,
  streaming_event: 0,
  streaming_actions: 0,
  streaming_gallery: 0,
  streaming_message: 0,
  is_started: false,
  is_stopped: false
}

const actions = {
  setKey({commit}, key){
    commit("STREAMING_KEY_CHANGED", key)
  },
  setStreamingEventMember({commit}, streaming_event_member){
    commit("STREAMING_EVENT_MEMBER_CHANGED", streaming_event_member)
  },
  setTitle({commit}, title) {
    commit("TITLE_CHANGED", title)
  },
  startStatusPolling({commit}) {
    commit("STATUS_POLLING", true)
  },
  stopStatusPolling({commit}) {
    commit("STATUS_POLLING", false)
  },
  setIsStarted({commit}, value) {
    commit("IS_STARTED", value)
  },
  setIsStopped({commit}, value) {
    commit("IS_STOPPED", value)
  }
}

const mutations = {
  STREAMING_KEY_CHANGED(state, key) {
    state.streaming_event_key = key
  },
  STREAMING_EVENT_MEMBER_CHANGED(state, streaming_event_member) {
    state.streaming_event_member = streaming_event_member
  },
  TITLE_CHANGED(state, title) {
    state.title = title
  },
  STATUS_POLLING(state, status) {
    state.status_polling = status
  },
  IS_POLLING(state, status) {
    state.is_polling = status
  },
  STREAMING_EVENT_UPDATED(state, status) {
    state.streaming_event = status
  },
  STREAMING_ACTIONS_UPDATED(state, status) {
    state.streaming_actions = status
  },
  STREAMING_GALLERY_UPDATED(state, status) {
    state.streaming_gallery = status
  },
  STREAMING_MESSAGE_UPDATED(state, status) {
    state.streaming_message = status
  },
  IS_STARTED(state, value) {
    state.is_started = value
  },
  IS_STOPPED(state, value) {
    state.is_stopped = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
