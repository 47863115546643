import ApiService from "@/service/api.service";

const EventService = {
  updateEvent: async function (store, key) {
    store.dispatch("event/setIsUpdating")
    await this.get(key)
        .then(response => {
          console.log(".... Streaming Event Updated ....")
          store.dispatch("event/updateEvent", response.data)
          store.dispatch("event/setNotUpdating")
        })
  },
  get: async function (key) {
    try {
      const event_api_url = "/api/v2/event/"
      return await ApiService.get(event_api_url + key);
    }
    catch (error) {
      console.log("getEvent fehlgeschlagen...")
      //throw(error)
    }
  }
}

export {EventService}
