<template>
  <div id="fullplayer-page">
    <Navbar
        v-if="showNavbar"
        :logo-url="getLogo"
        :menu-items="getMenue">
    </Navbar>

    <!-- Header Countdown / Videoplayer -->
    <div class="header-media-container position-relative" v-if="!isEventLoading">
      <transition name="fade">
        <HeaderVideoPlayer v-if="showPlayer"></HeaderVideoPlayer>
      </transition>
      <HeaderCountdown
          v-if="showCountdown"
          :event-date="computedStartTime"
          :background-image="getHeaderImage">
      </HeaderCountdown>
    </div>
    <!-- Header Countdown / Videoplayer -->

    <MediainfoPlayerControls
        v-if="showUserControls"
        :event-title="streaming_event.name">
    </MediainfoPlayerControls>

    <MediainfoEventInfo
        v-if="has_data && streaming_event.name && showEventInfo"
        :event-date="streaming_event.field_event_date"
        :event-title="streaming_event.name"
        :event-description="streaming_event.field_short_description">
    </MediainfoEventInfo>


    <!-- PreLive Player -->
    <template v-if="hasPreviewPlayer && showPreviewPlayer">
      <div class="video-pre-live-container">
        <div class="container-md py-3 py-md-5">
          <MiniVideoPlayer></MiniVideoPlayer>
        </div>
      </div>
    </template>

    <!-- Buttons für Benutzer / Upload / Fragen Stellen etc.) -->
    <div class="user-controls-container" v-if="false">
      <div class="container-md py-3 py-md-5">
        <UserControls/>
      </div>
    </div>

    <!-- Image Gallery -->
    <div class="gallery-container" v-if="streaming_event.field_streaming_gallery">
      <div class="container-md">
        <Gallery/>
      </div>
    </div>

    <!-- Inhaltsbereich -->
    <div class="info-container">
      <div class="container-md py-md-5 min-vh-35">
        <div v-if="streaming_event.field_body" v-html="streaming_event.field_body"></div>
      </div>
    </div>

    <!-- Footer -->
    <FooterNav
        v-if="showFooter"
        :impressum-text="streaming_event.field_impressum"
        :datenschutz-text="streaming_event.field_datenschutz">
    </FooterNav>

    <loading :active.sync="isEventLoading"></loading>
  </div>
</template>

<script>
import FooterNav from "@/components/FooterNav";
import Navbar from "@/components/Navbar"
import Loading from 'vue-loading-overlay'

import {mapGetters, mapState} from "vuex"

import 'vue-loading-overlay/dist/vue-loading.css'
import MiniVideoPlayer from "@/components/MiniVideoPlayer"
import Gallery from "@/components/Gallery"
import UserControls from "@/components/UserControls"
import VueFullscreen from "vue-fullscreen"
import HeaderCountdown from "@/components/HeaderCountdown"

import Vue from 'vue'
import HeaderVideoPlayer from "@/components/HeaderVideoPlayer";
import MediainfoEventInfo from "@/components/MediainfoEventInfo";
import MediainfoPlayerControls from "@/components/MediainfoPlayerControls";
import {EventService} from "@/service/event.service";

// import {EventService} from "@/service/event.service"

Vue.use(VueFullscreen)

export default {
  name: 'FullPlayerPage',
  components: {
    Navbar,
    HeaderVideoPlayer,
    HeaderCountdown,
    MediainfoEventInfo,
    MediainfoPlayerControls,
    UserControls,
    Gallery,
    MiniVideoPlayer,
    FooterNav,
    Loading
  },
  data() {
    return {
      poll: false,
      pollIntervalInstance: null,
      pollIntervalTime: 2000,
      fullscreen: false,
    }
  },
  computed: {
    ...mapState("event", ['streaming_event', 'has_data', 'is_started', 'is_stopped']),
    ...mapState("app", ['status_polling', 'streaming_event_key']),
    ...mapGetters("event", [
      'getHeaderImage',
      'getDate',
      'hasPreLive',
      "getLogo",
      "getMenue"
    ]),
    showNavbar() {
      return (this.streaming_event.field_logo_media.length > 0 || this.streaming_event.field_menue.length > 0)
    },
    hasPreviewPlayer() {
      return (this.streaming_event.field_pre_live_video_media.length > 0)
    },
    showFooter() {
      return (this.streaming_event.field_impressum.length > 0 || this.streaming_event.field_datenschutz.length > 0)
    },
    isEventLoading() {
      return !(this.has_data)
    },
    key() {
      return this.$route.params.key
    },
    computedStartTime() {
      const now = new Date();
      const target = this.getDate
      return target - now;
    },
    computedHeroStyle() {
      return {
        "background-image": "url('" + this.getHeaderImage + "')",
        color: 'white'
      }
    },
    showCountdown() {
      return (!this.is_started)
    },
    showPlayer() {
      return (this.is_started && this.streaming_event.field_streaming_key.length > 0)
    },
    showEventInfo() {
      return (!this.is_started)
    },
    showUserControls() {
      return (this.is_started && this.streaming_event.field_streaming_key.length > 0)
    },
    showPreviewPlayer() {
      return (!this.is_started)
    }
  },
  watch: {
    is_started(value) {
      if (value) {
        this.fullplayer_startEvent()
      }
    },
  },
  methods: {
    fullplayer_startEvent() {
      // Erzwingt das neue laden vom Event damit wir den StreamingKey bekommen...
      EventService.updateEvent(this.$store, this.streaming_event_key).then(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      })
    }
  },
  mounted() {
    //
    // Streaming Event Key setzen
    // Einmal Streaming Event fetchen...
    // Polling Daemon starten...
    //
    this.$store.dispatch("app/setKey", this.key).then(() => {
      this.$store.dispatch("app/startStatusPolling")
    })
  },
  beforeDestroy() {
    this.$store.dispatch("app/stopStatusPolling")
  }
}
</script>

<style lang="scss" scoped>

</style>
