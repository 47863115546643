import ApiService from "@/service/api.service"

const GalleryService = {
  update: async function (store, key) {
    store.commit("gallery/UPDATING", true)
    await this.get(key)
        .then(response => {
          store.commit("gallery/UPDATE_ITEMS", response.data.streaming_gallery)
        })
        .catch(error => {
          console.log(error)
        })
    store.commit("gallery/UPDATING", false)
  },
  get: async function (key) {
    return await ApiService.get("api/v2/gallery/" + key)
  }
}

export {GalleryService}
