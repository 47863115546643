<template>
  <div id="upload-page">
    <div class="container">
      <b-row>
        <b-col>
          <FileUpload :streaming_key="key"></FileUpload>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload";

export default {
  name: "UploadPage",
  components: {FileUpload},
  computed: {
    key() {
      let route_key = this.$route.params.key
      console.log(route_key)
      return route_key
    }
  },
  created() {
    console.log('route', this.$route.params.key);
  }
}
</script>

<style scoped>

</style>