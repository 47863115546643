import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import ApiService from "./service/api.service"

// #######################################
// Bootstrap & CSS
// #######################################
import {BootstrapVue} from 'bootstrap-vue'
import './assets/app.scss'

// #######################################
// CountDown
// #######################################
import VueCountdown from '@chenfengyuan/vue-countdown';

// #######################################
// FontAwesome
// #######################################
import {library} from '@fortawesome/fontawesome-svg-core'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
  faCalendarAlt,
  faCamera,
  faCloudUpload,
  faCommentAltEdit,
  faCommentSmile,
  faFileDownload,
  faHeart,
  faKey,
  faLongArrowUp,
  faPlay,
  faThumbsUp,
  faVolume,
  faVolumeMute,
  faVolumeSlash,
  faVolumeUp
} from '@fortawesome/pro-light-svg-icons'

// #######################################
// MomentJS
// #######################################
import VueMoment from 'vue-moment'
import moment from 'moment'
import de from 'moment/locale/de'

// #######################################
// VueFullscreen
// #######################################
import VueFullscreen from "vue-fullscreen";

// #######################################
// Particles JS
// #######################################
import Particles from "particles.vue";
Vue.use(Particles);

// #######################################
// Init Vue
// #######################################

Vue.use(BootstrapVue)
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueFullscreen)

library.add(faChevronDown)
library.add(faPlay)
library.add(faCalendarAlt)
library.add(faThumbsUp)
library.add(faHeart)
library.add(faVolumeMute)
library.add(faVolume)
library.add(faVolumeUp)
library.add(faVolumeSlash)
library.add(faCamera)
library.add(faCommentSmile)
library.add(faCloudUpload)
library.add(faFileDownload)
library.add(faLongArrowUp)
library.add(faCommentAltEdit)
library.add(faKey)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMoment, {moment, de})

// #######################################
// Weiter gehts...
// #######################################
Vue.config.productionTip = false

ApiService.init()

// #######################################
new Vue({router, store, render: h => h(App)}).$mount('#app')
