import ApiService from "@/service/api.service"

const ChatService = {
  submitChat: async function (data) {
    try {
      const response = await ApiService.post("/api/" + process.env.VUE_APP_API + "/message/", data)
      return response.data
    }
    catch (error) {
      console.log(error)
      throw (error)
    }
  }
}

export {ChatService}