<template>
  <div class="header-media text-center bg-black show-video">
    <fullscreen v-model="fullscreen">
      <div class="header-media__video video position-relative">
        <VideoPlayer
            v-if="getStreamingSource || getPosterImage"
            :player-poster="getPosterImage"
            :player-autoplay=streaming_event.field_streaming_autoplay
            :player-seekbar=streaming_event.field_streaming_seekbar
            :player-source="getStreamingSource">
        </VideoPlayer>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import VideoPlayer from "@/components/VideoPlayer"

export default {
  name: "HeaderVideoPlayer",
  components: {
    VideoPlayer
  },
  data() {
    return {
      fullscreen: false
    }
  },
  computed: {
    ...mapState("event", ['streaming_event']),
    ...mapGetters("event", [
      'getPosterImage',
      'getStreamingSource',
    ])
  }
}
</script>

<style scoped lang="scss">
.header-media {
  &__video {
    width: 100%;
    max-width: 1800px;
    position: relative;
    margin: 0 auto;
    height: 100%;
  }
}
</style>