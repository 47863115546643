const state = {
  isUpdating: false,
  items: [
  ]
}

const mutations = {
  UPDATING(state, value) {
    state.isUpdating = value
  },
  UPDATE_ITEMS(state, gallery_data) {
    for (let item of gallery_data['default']) {
      if (!state.items.find(x => x.uuid === item.uuid)) {
        let it = {
          uuid: item.uuid,
          url: 'https://digitalstream.online/' + item.image_url[0].full.url,
          thumb: 'https://digitalstream.online/' + item.image_url[0].medium.url
        }
        state.items.unshift(it)
      }
    }
  },
}

export const gallery = {
  namespaced: true,
  state,
  mutations
}