<template>
  <div class="videoplayer-controls">
    <button v-on:click.prevent="handleMuteClick" class="btn shadow-none p-0" v-if="muted">
      <font-awesome-icon :icon="['fal', 'volume-slash']" size="2x"/>
    </button>
    <button v-on:click.prevent="handleMuteClick" class="btn shadow-none p-0" v-if="!muted">
      <font-awesome-icon :icon="['fal', 'volume-up']" size="2x"/>
    </button>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "VideoControls",
  computed: {
    ...mapState("videoplayer", ['muted']),
  },
  methods: {
    handleMuteClick() {
      this.$root.$emit('handleVideoplayerMuteControl')
    }
  }
}
</script>

<style scoped>

</style>