import ApiService from "@/service/api.service"

const DownloadService = {
  getDownloads: async function () {
    try {
      const response = await ApiService.get("/api/" + process.env.VUE_APP_API + "/download")
      return response.data
    }
    catch (error) {
      console.log("Download Service Error: " + error)
      throw (error)
    }
  },
  tellUsage: async function (streaming_event_member_uuid) {
    try {
      const response = await ApiService.post("/api/" + process.env.VUE_APP_API + "/download", streaming_event_member_uuid)
      return response
    }
    catch (error) {
      console.log("Download Service Error: " + error)
      throw (error)
    }
  }
}

export {DownloadService}