import ApiService from "@/service/api.service";

const UpdatecheckService = {
  updateStatus: async function (store, key) {
    store.commit("app/IS_POLLING", true)
    await this.get(key)
        .then(function (response) {
          if (response !== undefined) {
            if (response.data.streaming_event !== store.state.app.streaming_event) {
              store.commit("app/STREAMING_EVENT_UPDATED", response.data.streaming_event)
            }

            if (response.data.streaming_actions !== store.state.app.streaming_actions) {
              store.commit("app/STREAMING_ACTIONS_UPDATED", response.data.streaming_actions)
            }

            if (response.data.streaming_gallery !== store.state.app.streaming_gallery) {
              store.commit("app/STREAMING_GALLERY_UPDATED", response.data.streaming_gallery)
            }

            if (response.data.streaming_message !== store.state.app.streaming_message) {
              store.commit("app/STREAMING_MESSAGE_UPDATED", response.data.streaming_message)
            }

            if (response.data.streaming_event_member !== store.state.app.streaming_event_member) {
              store.dispatch("app/setStreamingEventMember", response.data.streaming_event_member)
            }

            // Eventuell zu sehr duplikat...
            store.dispatch("app/setIsStarted", response.data.is_started)
            store.dispatch("app/setIsStopped", response.data.is_stopped)
            store.dispatch("event/setIsStarted", response.data.is_started)
            store.dispatch("event/setIsStopped", response.data.is_stopped)

            store.commit("app/IS_POLLING", false)
          }
        }).catch(error => {
          console.log("Error: " + error)
        })
  },
  get: async function (key) {
    return await ApiService.get("/api/v2/status/" + key);
  }
}

export {UpdatecheckService}
