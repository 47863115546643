<template>
  <div class="countdown-content">
    <countdown :time="eventdate" :interval="1000" class="text-white"
               v-if="eventdate > 0 && countDownVisible" @end="handleCountdownEnd">
      <template slot-scope="props">
        <span class="text-white text-center font-weight-bold display-5">Wir starten in:</span>
        <table class="w-100 mt-2">
          <tr class="display-3 d-flex font-weight-bold">
            <td class="col-3">{{ props.days }}</td>
            <td class="col-3">{{ props.hours }}</td>
            <td class="col-3">{{ props.minutes }}</td>
            <td class="col-3">{{ props.seconds }}</td>
          </tr>
          <tr class="d-flex">
            <td class="col-3">Tage</td>
            <td class="col-3">Stunden</td>
            <td class="col-3">Minuten</td>
            <td class="col-3">Sekunden</td>
          </tr>
        </table>
      </template>
    </countdown>
  </div>
</template>

<script>
export default {
  name: "EventCountDown",
  data() {
    return {
      countDownVisible: true,
    }
  },
  props: ['eventdate'],
  methods: {
    handleCountdownEnd: function () {
      //this.countDownVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>

.countdown-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 360px;
  height: 110px;
  border: 2px solid white;
  margin-left: -180px;
  margin-top: -55px;
  display: block;
  animation: pulseicon 1s alternate ease-in-out infinite;
  padding: 10px 0 10px 0;
  transition: all .2s ease-in-out;
  line-height: 1;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  outline: 0;
  font-size: 14px;

  .display-3 {
    font-size: 2.3rem;
  }
}

@media (min-width: 576px) {
  .countdown-content {
    font-size: 24px;
    width: 580px;
    height: 200px;
    margin-left: -290px;
    margin-top: -85px;

    .display-3 {
      font-size: 4.5rem;
    }
  }
}

</style>