<template>
  <div class="login-page min-vh-100" :style="backgroundStyle">
    <Particles id="tsparticles" :options="particleConfig" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded"></Particles>
    <div class="container-fluid min-vh-100">
      <div class="row justify-content-center align-self-center min-vh-100 align-items-center">
        <div class="col-md-8 col-lg-8 col-xl-6 mx-auto">
          <transition name="bounceDown">
            <div class="text-center mb-5" v-if="logo">
              <img :src="logo.url" alt="Logo" :height="logo.height"/>
            </div>
          </transition>
          <!-- Login Form -->
          <b-form class="p-4 p-sm-5 bg-white shadow-lg position-relative" @submit.prevent="handleSubmit">
            <b-form-group class="mb-4">
              <b-form-input
                  v-model="hashcode"
                  title="Zugangscode"
                  size="lg"
                  :state="hashCodeValidate"
                  placeholder="Bitte gib Deinen Zugangscode ein."
                  autocomplete="off">
              </b-form-input>

              <b-form-invalid-feedback
                  :state="hashCodeValidate">{{ hashCodeValidateMsg }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-button type="submit" block size="lg" :disabled="isDisabled" class="mt-4">
              <font-awesome-icon :icon="['fal', 'key']" class="mr-2"></font-awesome-icon>
              Zugang
            </b-button>
            <loading :active.sync="refreshing" :is-full-page=false></loading>
          </b-form>
          <!-- Login Form Ende -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import router from "@/router";
import Loading from 'vue-loading-overlay'
import {CheckHashService} from "@/service/checkhash.service"
import {PlayerSettingsService} from "@/service/playersettings.service"
import {mapActions} from "vuex";

export default {
  name: "HashCodePage.vue",
  components: {Loading},
  data() {
    return {
      id: 'default',
      refreshing: false,
      hashCodeValidate: null,
      hashCodeValidateMsg: '',
      hashcode: "",
      backgroundStyle: {
        'background-color': ''
      },
      logo: null,
      particleConfig: {
        fps_limit: 120,
        interactivity: {
          detect_on: "canvas",
          events: {
            onclick: {enable: true, mode: "push"},
            onhover: {
              enable: true,
              mode: "attract",
              parallax: {enable: false, force: 60, smooth: 30}
            },
            resize: true
          },
          modes: {
            push: {quantity: 4},
            attract: {distance: 200, duration: 0.4, factor: 5}
          }
        },
        particles: {
          color: {value: "#fff"},
          line_linked: {
            color: "#fff",
            distance: 500,
            enable: false,
            opacity: 0.2,
            width: 1
          },
          move: {
            attract: {enable: false, rotateX: 600, rotateY: 1200},
            bounce: false,
            direction: "none",
            enable: true,
            out_mode: "out",
            random: false,
            speed: 1,
            straight: false
          },
          number: {density: {enable: true, value_area: 1000}, value: 20},
          opacity: {
            anim: {enable: false, opacity_min: 0.1, speed: 1, sync: false},
            random: false,
            value: 0.1
          },
          size: {
            anim: {enable: false, size_min: 1, speed: 40, sync: false},
            random: true,
            value: 200
          }
        },
        retina_detect: true
      }
    }
  },
  computed: {
    // Sperrt den Submit Button wenn noch kein HashCode eingegeben wurde
    isDisabled() {
      return this.hashcode.length < 3;
    }
  },
  methods: {
    ...mapActions('app', ["setKey", "setStreamingEventMember"]),
    async fetchPlayerSettings() {
      await PlayerSettingsService.get(this.id).then(result => {
        this.logo = result.data[0].logo
        this.backgroundStyle['background-color'] = result.data[0].backgroundColor
      })
    },
    async handleSubmit() {
      try {
        this.refreshing = true
        await CheckHashService.checkHash(this.hashcode)
            .then((response) => {
              this.refreshing = false

              var hash = String(response.hash)

              if (hash.length > 0 && hash.length < 3) {
                this.hashCodeValidate = false
                this.hashCodeValidateMsg = response.msg
              }
              else {
                this.hashCodeValidate = true
                this.hashCodeValidateMsg = "Zugangscode gültig. Bitte warten..."

                this.setKey(response.hash)
                this.setStreamingEventMember(response.streaming_event_member)

                router.push({name: 'fullplayerpage', params: {key: response.hash}})
              }
            })
      }
      catch (error) {
        this.msg = error.response.data.msg;
      }
    },
    particlesLoaded() {
      console.log("PLoaded")
    },
    particlesInit() {
      console.log("Pinit")
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
    this.fetchPlayerSettings()
  }
}
</script>

<style scoped lang="scss">
.login-page {
  transition: background-color 1000ms linear;
}

#tsparticles {
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
